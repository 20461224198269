<template>
  <div class="container-fluid">
    <div>
      <h1 class="title">BIELbit.Crashlytics</h1>
      <form class="form-inline">
        <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
          <div class="input-group-prepend">
            <div class="input-group-text">Login</div>
          </div>
          <input
            v-model="form.login"
            type="text"
            class="form-control"
            id="__BVID__7"
          /><!---->
        </div>
        <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
          <div class="input-group-prepend">
            <div class="input-group-text">Hasło</div>
          </div>
          <input
            v-model="form.password"
            type="password"
            class="form-control"
            id="__BVID__8"
          /><!---->
        </div>
        <button
          @click.prevent="pobierz"
          type="button"
          class="btn btn-secondary"
        >
          Pobierz
        </button>
      </form>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <td v-for="(column, i) in columns" :key="i">{{ column }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in dane" :key="i">
              <td v-for="(column, j) in columns" :key="j">
                {{ row["info"][column] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
@Component({
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
      dane: [],
      columns: [],
    };
  },
  computed: {
    get_API_BASE_URL() {
      return "https://crashlytics.bielbit.pl/api/";
    },
    get_DATA_URL() {
      let url = "";
      url += this.get_API_BASE_URL;
      url += "get/";
      return url;
    },
  },
  methods: {
    pobierz() {
      fetch(this.get_DATA_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.form),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.error)
            if (data.error === "Forbidden") alert("Błędny login i/lub hasło");
            else alert(data.error);
          else {
            const x = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              const info = element.info;
              for (const key in info)
                if (Object.prototype.hasOwnProperty.call(info, key))
                  if (x.indexOf(key) === -1) x.push(key);
            }
            this.$data.columns = x;
            if (data.length === 0) alert("Brak danych w bazie danych");
            else this.$data.dane = data;
          }
        })
        .catch(function (error) {
          alert("Wystąpił błąd. Szczegóły w konsoli developerskiej");
          // eslint-disable-next-line no-console
          console.error("Error:", error);
        });
    },
  },
})
export default class Index extends Vue {}
</script>

<style>
.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}
</style>
